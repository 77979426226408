/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// NOTE: We need to replace the hydrate function here because otherwise the SSR
// will produce weird results:
// https://github.com/gatsbyjs/gatsby/issues/8560#issuecomment-535265414
const ReactDOM = require('react-dom')

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

// You can delete this file if you're not using it
